(function () {
  'use strict';

  angular
    .module('neo.home.facilities.facility')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.facilities.facility', {
        url: '/{facilityId}',
        templateUrl: 'home/facilities/facility/facility.tpl.html',
        controller: 'FacilityCtrl',
        controllerAs: 'vm',
        resolve :{
          towns: function (Poblacions) {
            return Poblacions.query().$promise;
          },
          townhalls : function(Ajuntaments){
            return Ajuntaments.query().$promise;
          },
          facility : function(Facilities, $stateParams){
            return Facilities.get({id: $stateParams.facilityId}).$promise;
          }
        }
      });
  }
}());
